import React from "react"
import DiagonalSlashSVG from "./svgs/DiagonalSlashSVG"

export default function Stripe({ id, className, title, children }) {
  return (
    <div
      id={id}
      className={
        "stripe heroToAnimate d-flex-column align-items-center py-5 overflow-hidden position-relative " +
        (className || "")
      }
    >
      <DiagonalSlashSVG />
      <div className="stripeHeader d-flex justify-content-center">
        <h2 className="stripeHeaderText">{title}</h2>
      </div>

      <div className="stripeContent d-flex justify-content-center m-4">
        {children}
      </div>
    </div>
  )
}
