import React from "react"

export default function Footer({ className, links, iconLinks }) {
  const linkListItems = links.map(link => {
    return (
      <div className="pb-3">
        <a className="footer" href={link.href}>
          {link.name}
        </a>
      </div>
    )
  })

  const verticalIconLinks = iconLinks.map(iconLink => {
    return (
      <div>
        <a
          href={iconLink.href}
          aria-label={iconLink.ariaLabel}
          target="_blank"
          className="footer"
        >
          <i className={iconLink.iconClass}></i>
        </a>
      </div>
    )
  })

  const horizontalIconLinks = iconLinks.map(iconLink => {
    return (
      <a
        href={iconLink.href}
        aria-label={iconLink.ariaLabel}
        target="_blank"
        className="footer px-2"
      >
        <i className={iconLink.iconClass}></i>
      </a>
    )
  })

  // plus one so that longer column is on left side, not right
  const middleIndex = Math.floor((linkListItems.length + 1) / 2)

  return (
    <footer className={className}>
      <div className="footer-background jumbotron jumbotron-fluid shadow px-5 pt-5 pb-1 mb-0">
        <div className="outline-holder">
          <svg width="100%" height="100%" viewBox="0 0 884 977">
            <g>
              <path
                d="M881.094,591.877l-879.011,382.71l0,-247.276l613.385,-241.16l265.626,-103.979l0,209.705Z"
                style={{
                  fill: "none",
                  fillRule: "nonzero",
                  stroke: "#ddd",
                  strokeWidth: "3px",
                }}
              />
              <path
                d="M881.094,382.172l-265.626,103.979l-613.385,-238.539l0,-245.529l879.011,380.089Z"
                style={{
                  fill: "none",
                  fillRule: "nonzero",
                  stroke: "#ddd",
                  strokeWidth: "3px",
                }}
              />
            </g>
          </svg>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-6 col-sm-4">
              {linkListItems.slice(0, middleIndex)}
            </div>
            <div className="col-6 col-sm-4">{linkListItems.slice(middleIndex)}</div>
            <div className="d-none d-sm-block col-sm-4">{verticalIconLinks}</div>
          </div>
          <div className="d-sm-none d-flex justify-content-center pt-3">
            {horizontalIconLinks}
          </div>
        </div>
        <hr className="my-4" />
        <p className="copyright text-center mb-2">
          © 2022 Vincent Di Nardo. All rights reserved.
        </p>
      </div>
    </footer>
  )
}
