import React from "react"
import Footer from "./Footer"
import Navbar from "./Navbar"
import links from "../shared_data/page_links"
import footerIconLinks from "../shared_data/external_links"

export default function Layout({ children }) {
  return (
    <div className="layoutContainer">
      <div className="layoutTop">
        <Navbar className="layoutNavbar" tabs={links.navbarLinks} />
        <div className="layoutContent">{children}</div>
      </div>

      <Footer
        className="layoutFooter"
        links={links.footerLinks}
        iconLinks={footerIconLinks}
      />
    </div>
  )
}
