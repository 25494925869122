const footerIconLinks = [
  {
    ariaLabel: "LinkedIn",
    href: "https://www.linkedin.com/in/vincent-di-nardo/",
    iconClass: "fab fa-linkedin",
  },
  {
    ariaLabel: "GitHub",
    href: "https://github.com/dinardov",
    iconClass: "fab fa-github-square",
  },
  {
    ariaLabel: "SoundCloud",
    href: "https://soundcloud.com/vince-di-nardo",
    iconClass: "fab fa-soundcloud",
  },
]

export default footerIconLinks
