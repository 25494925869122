import React from "react"
import { Link } from "gatsby"
import pageLinks from "../shared_data/page_links"

export default function Navbar({ className, tabs }) {
  const tabListItems = tabs.map(tab => {
    return (
      <li className="nav-item px-md-1 px-lg-2" key={tab}>
        <Link className="nav-link py-4" to={tab.href}>
          {tab.name}
        </Link>
      </li>
    )
  })

  return (
    <nav
      className={
        "navbar navbar-expand-md navbar-light fixed-top py-0 px-0 px-md-1 shadow-sm " +
        className
      }
    >
      <div className="svg-holder d-none d-md-inline-block">
        <svg
          className="main-logo"
          width="100%"
          height="100%"
          viewBox="0 0 884 977"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlSpace="preserve"
          xmlnsSerif="http://www.serif.com/"
          style={{
            fillRule: "evenodd",
            clipRule: "evenodd",
            strokeLinejoin: "round",
            strokeMiterlimit: 2,
          }}
        >
          <g>
            <path
              d="M881.094,591.877l-879.011,382.71l0,-247.276l613.385,-241.16l265.626,-103.979l0,209.705Z"
              style={{
                fill: "none",
                fillRule: "nonzero",
                stroke: "#000",
                strokeWidth: "40px",
              }}
            />
            <path
              d="M881.094,382.172l-265.626,103.979l-613.385,-238.539l0,-245.529l879.011,380.089Z"
              style={{
                fill: "none",
                fillRule: "nonzero",
                stroke: "#000",
                strokeWidth: "40px",
              }}
            />
          </g>
        </svg>
      </div>
      <Link className="navbar-brand my-3 mx-0 px-3 py-0" to="/">
        {" "}
        Vincent Di Nardo
      </Link>
      {/* <button
        className="navbar-toggler my-3 mx-3"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button> */}

      <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul className="navbar-nav mr-auto mt-lg-0 py-0">
          {tabListItems}
          <li className="nav-item px-md-1 px-lg-2 d-md-none">
            <a className="nav-link py-4" href="#">
              Contact
            </a>
          </li>
        </ul>

        <button
          className="btn btn-outline-primary my-2 m-0 d-none d-md-inline"
          onClick={() => {window.location.href = pageLinks.navbarButtons[0].href}}
        >
          {pageLinks.navbarButtons[0].name}
        </button>
      </div>
    </nav>
  )
}
